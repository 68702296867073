import React, { useState } from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import "./Help.css";
import { useTranslation, Trans } from "react-i18next";
const textToImage = require("text-to-image");

const GreenCheckMark = () => {
  return <i className="fas fa-check-circle" style={{ color: "green" }} />;
};

const LegendGreenBox = () => {
  return <i className="fas fa-stop" style={{ color: "green" }} />;
};

const LegendOrangeBox = () => {
  return <i className="fas fa-stop" style={{ color: "orange" }} />;
};

const Register = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t("helpPage.register.title")}</h3>
      <p>
        <ul>
          <li>{t("helpPage.register.item1")}</li>
          <li>
            <Trans i18nKey="helpPage.register.item2">
              If you are a new user then click on the <b>Create Account</b>
              option, enter your details and click <b>Create Account</b> button.
            </Trans>
          </li>
          <li>{t("helpPage.register.item3")}</li>
          <li>{t("helpPage.register.item4")}</li>
          <li>
            <Trans i18nKey="helpPage.register.item5">
              Once you enter the confirmation code click <b>Confirm</b> and now
              you can use your account credentials (email and password) to
              login.
            </Trans>
          </li>
          <li>{t("helpPage.register.item6")}</li>
          <li>
            <Trans i18nKey="helpPage.register.item7">
              Enter the details and click <b>Submit</b>. If there are any errors
              they will be displayed at the bottom of the page. Update the form
              accordingly and submit.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.register.item8">
              <b>For Temples: </b> After making the account, admins will
              validate your details and a <GreenCheckMark /> icon will appear
              next to the name. <b>Important: </b> Updating any sensitive data
              later will result in losing the icon temporarily until admins
              re-validate.
            </Trans>
          </li>
        </ul>
      </p>
    </div>
  );
};

const Reservation = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t("helpPage.reservation.title")}</h3>
      <p>
        <ul>
          <li>
            <Trans i18nKey="helpPage.reservation.item1">
              To make a reservation first you need to have an account. Please
              see <b>Registration</b> page.
            </Trans>
          </li>
          <li>{t("helpPage.reservation.item2")}</li>
          <li>{t("helpPage.reservation.item3")}</li>
          <li>{t("helpPage.reservation.item4")}</li>
          <li>{t("helpPage.reservation.item5")}</li>
          <li>
            <Trans i18nKey="helpPage.reservation.item6">
              From the new event popup click on the <b>ADD</b> button. The
              reservation page will load.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.reservation.item7">
              Enter the reservation details and click on <b>Submit</b>.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.reservation.item8">
              Your reservations will appear on the Temple Calendar and in your{" "}
              <b>My Bookings</b> page.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.reservation.item9">
              <b>Note: </b>Once the reservation day has been passed, any
              non-repeating reservations will get removed automatically from the
              calendar.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.reservation.item10">
              Both Donors and Temples can make reservations. <b>Hint:</b>{" "}
              Temples can prevent donors from booking dates by adding
              reservations on those dates.
            </Trans>
          </li>
        </ul>
      </p>
    </div>
  );
};

const Update = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t("helpPage.updateReservation.title")}</h3>
      <p>
        <h5>{t("helpPage.updateReservation.donor.title")}</h5>
        <ul>
          <li>
            <Trans i18nKey="helpPage.updateReservation.donor.item1">
              Go to <b>My Bookings</b> page (appears only when you are logged
              in) and click on the reservation you need to update/remove.
            </Trans>
          </li>
          <li>{t("helpPage.updateReservation.donor.item2")}</li>
          <li>{t("helpPage.updateReservation.donor.item3")}</li>
          <li>
            <Trans i18nKey="helpPage.updateReservation.donor.item4">
              If you click edit it will take you to the update reservation page,
              enter the new details and click <b>Submit</b>.
            </Trans>
          </li>
        </ul>
        <h5>{t("helpPage.updateReservation.temple.title")}</h5>
        <ul>
          <li>{t("helpPage.updateReservation.temple.item1")}</li>
          <li>{t("helpPage.updateReservation.temple.item2")}</li>
          <li>
            <Trans i18nKey="helpPage.updateReservation.donor.item3">
              If you are already logged in with your temple account credentials,
              the <b>delete</b> button will appear on the popup menu.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.updateReservation.donor.item4">
              Click <b>delete</b> button and it will ask you for the
              confirmation and once you confirm the reservation will get
              removed.
            </Trans>
          </li>
        </ul>
      </p>
    </div>
  );
};

const Reminders = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t("helpPage.reminders.title")}</h3>
      <p>
        <ul>
          <li>{t("helpPage.reminders.item1")}</li>
          <li>{t("helpPage.reminders.item2")}</li>
          <li>
            <Trans i18nKey="helpPage.reminders.item3">
              Click in the link and it will take you to the reservation
              confirmation page. Click on the <b>Confirm</b> button to confirm.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.reminders.item4">
              Once you confirm, it will appear on the calendar with a
              <GreenCheckMark /> symbol next to the event time in the event
              popup.
            </Trans>
          </li>
          <li>{t("helpPage.reminders.item5")}</li>
          <li>{t("helpPage.reminders.item6")}</li>
        </ul>
      </p>
    </div>
  );
};

const FAQ = () => {
  const { t } = useTranslation();
  const [emailImage, setEmailImage] = useState("");
  textToImage
    .generate("donatetemple@gmail.com", {
      lineHeight: 25,
      maxWidth: 270,
      textColor: "blue",
      fontWeight: "bold",
      fontSize: 20,
    })
    .then(function (dataUri) {
      setEmailImage(dataUri);
    });

  return (
    <div>
      <h3>{t("helpPage.faq.title")}</h3>
      <p>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/RetMNgGVnkE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          allowfullscreen
        ></iframe>
        <ul>
          <li>
            <Trans i18nKey="helpPage.faq.item1">
              Temples that appear with a <GreenCheckMark /> icon means that
              admins have validated the temple details.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="helpPage.faq.item2">
              On the temple reservation calendar, <LegendGreenBox />
              highlighted reservations indicate annually repeating events and
              <LegendOrangeBox /> highlighted events are non-repeating events.
            </Trans>
          </li>
          <li>{t("helpPage.faq.item3")}</li>
          <li>{t("helpPage.faq.disclaimer")}</li>
          <li>
            <Trans i18nKey="helpPage.faq.item4">
              <b>
                If you find any issues, problems or queries relating to the
                website, please help us to improve it by sending an email to:
              </b>
            </Trans>
            <img src={emailImage} alt="email" />
          </li>
        </ul>
      </p>
    </div>
  );
};

const Help = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="main">
        <Tab.Container id="left-tabs-example" defaultActiveKey="faq">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="faq">{t("helpPage.faq.title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="register">
                    {t("helpPage.register.title")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reservation">
                    {t("helpPage.reservation.title")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="update">
                    {t("helpPage.updateReservation.title")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reminders">
                    {t("helpPage.reminders.title")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="register">
                  <Register />
                </Tab.Pane>
                <Tab.Pane eventKey="reservation">
                  <Reservation />
                </Tab.Pane>
                <Tab.Pane eventKey="update">
                  <Update />
                </Tab.Pane>
                <Tab.Pane eventKey="reminders">
                  <Reminders />
                </Tab.Pane>
                <Tab.Pane eventKey="faq">
                  <FAQ />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Help;
