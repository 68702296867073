import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import countries from "./countries.json";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [role, setRole] = useState("doner");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [number, setNumber] = useState("");
  const [streetname, setStreetname] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [accountDetails, setAccountDetails] = useState("");
  const [gmapUrl, setGmapUrl] = useState("");
  const [templeUrl, setTempleUrl] = useState("");
  const [fNumberofPersons, setFNumberOfPersons] = useState(0);
  const [fCostPerPerson, setFCostPerPerson] = useState(0);
  const [bNumberofPersons, setBNumberOfPersons] = useState(0);
  const [bCostPerPerson, setBCostPerPerson] = useState(0);
  const [lNumberofPersons, setLNumberOfPersons] = useState(0);
  const [lCostPerPerson, setLCostPerPerson] = useState(0);
  const [tNumberofPersons, setTNumberOfPersons] = useState(0);
  const [tCostPerPerson, setTCostPerPerson] = useState(0);
  const [moneyTick, setMoneyTick] = useState(false);
  const [bringFoodTick, setBringFoodTick] = useState(false);
  const [cookTick, setCookTick] = useState(false);
  const [smsReminder, setSMSReminder] = useState(2);
  const [emailReminder, setEmailReminder] = useState(7);
  const [additional, setAdditional] = useState("");
  const [status, setStatus] = useState("");
  const [showFull, setShowFull] = useState({ display: "none" });
  const [showPartial, setShowPartial] = useState({ display: "none" });
  const [statusColor, setStatusColor] = useState("alert alert-light");
  const [showTemple, setShowTemple] = useState({ display: "none" });
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  // fetch and populate data from DB when loading the page
  useEffect(() => {
    async function fetchData() {
      try {
        let user = null;
        try {
          user = await Auth.currentAuthenticatedUser();
        } catch (err) {
          window.location.href = "/login?url=/account";
        }
        const session = await Auth.currentSession();

        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        };
        const response = await fetch(
          process.env.REACT_APP_BACKEND_DOMAIN + "/api/user/" + user.username,
          requestOptions
        );
        const data = await response.json();

        //load data
        if (data) {
          setRole(data.role);
          setName(data.name);
          setEmail(data.email);
          setPhone(data.phone_number);
          if (data.address) {
            setNumber(data.address.number);
            setStreetname(data.address.streetname);
            setCity(data.address.city);
            setProvince(data.address.province);
            setPostal(data.address.postalcode);
            setCountry(data.address.country);
          }
          if (data.donationMethod) {
            setMoneyTick(data.donationMethod.indexOf("money-payment") > -1);
            setBringFoodTick(data.donationMethod.indexOf("bring-food") > -1);
            setCookTick(data.donationMethod.indexOf("cook-at-temple") > -1);
          }
          setAccountDetails(data.accountDetails);
          setTempleUrl(data.templeUrl ? data.templeUrl : "");
          setGmapUrl(data.gmapUrl ? data.gmapUrl : "");
          setAdditional(data.additionalDetails ? data.additionalDetails : "");
          setSMSReminder(data.smsReminderPeriod ? data.smsReminderPeriod : 2);
          setEmailReminder(
            data.emailReminderPeriod ? data.emailReminderPeriod : 7
          );
          if (data.reservation) {
            const full = data.reservation.fullReservation != null;
            const partial = data.reservation.partialReservation != null;
            fullChecked(full);
            partialChecked(partial);

            if (full) {
              setFNumberOfPersons(
                data.reservation.fullReservation.numberOfPersons
              );
              setFCostPerPerson(data.reservation.fullReservation.costPerPerson);
            }
            if (partial) {
              setBNumberOfPersons(
                data.reservation.partialReservation.breakfast.numberOfPersons
              );
              setBCostPerPerson(
                data.reservation.partialReservation.breakfast.costPerPerson
              );
              setLNumberOfPersons(
                data.reservation.partialReservation.lunch.numberOfPersons
              );
              setLCostPerPerson(
                data.reservation.partialReservation.lunch.costPerPerson
              );
              setTNumberOfPersons(
                data.reservation.partialReservation.tea.numberOfPersons
              );
              setTCostPerPerson(
                data.reservation.partialReservation.tea.costPerPerson
              );
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  // Submit the new data
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus("");
    setStatusColor("alert alert-light");
    let data = {
      name: name,
      phone_number: phone,
      address: {
        number: number,
        streetname: streetname,
        city: city,
        province: province,
        postalcode: postal,
        country: country,
      },
      role: role,
    };
    if (role === "temple") {
      data.accountDetails = accountDetails;
      if (templeUrl !== "") data.templeUrl = templeUrl;
      if (gmapUrl !== "") data.gmapUrl = gmapUrl;
      if (additional !== "") data.additionalDetails = additional;
      let methods = [];
      if (moneyTick) methods.push("money-payment");
      if (bringFoodTick) methods.push("bring-food");
      if (cookTick) methods.push("cook-at-temple");
      data.donationMethod = methods;
      data.reservation = {};
      if (showFull.display !== "none") {
        data.reservation.fullReservation = {};
        data.reservation.fullReservation.numberOfPersons = fNumberofPersons;
        data.reservation.fullReservation.costPerPerson = fCostPerPerson;
      }
      if (showPartial.display !== "none") {
        data.reservation.partialReservation = {
          breakfast: {},
          lunch: {},
          tea: {},
        };
        data.reservation.partialReservation.breakfast.numberOfPersons = bNumberofPersons;
        data.reservation.partialReservation.breakfast.costPerPerson = bCostPerPerson;
        data.reservation.partialReservation.lunch.numberOfPersons = lNumberofPersons;
        data.reservation.partialReservation.lunch.costPerPerson = lCostPerPerson;
        data.reservation.partialReservation.tea.numberOfPersons = tNumberofPersons;
        data.reservation.partialReservation.tea.costPerPerson = tCostPerPerson;
      }
      data.smsReminderPeriod = smsReminder;
      data.emailReminderPeriod = emailReminder;
      console.log(data);
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const requestOptions = {
        method: "PATCH",
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/user/" + user.username,
        requestOptions
      );
      response = await response.json();
      setLoading(false);
      const message = JSON.stringify(response);
      if (message.includes("status")) {
        setStatus(response.status);
        setStatusColor("alert alert-success");
      } else {
        setStatus(response.error);
        setStatusColor("alert alert-danger");
      }
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };

  // Update the form when switch between doner and temple option
  useEffect(() => {
    if (role === "temple") {
      document.getElementById("temple").checked = true;
      setShowTemple({});
    } else {
      document.getElementById("doner").checked = true;
      setShowTemple({ display: "none" });
    }
  }, [role]);

  // Update form check full reservation ticked
  const fullChecked = (checked) => {
    if (checked) {
      document.getElementById("full").checked = true;
      setShowFull({});
    } else {
      document.getElementById("full").checked = false;
      setShowFull({ display: "none" });
    }
  };

  // Update form partial reservation ticked
  const partialChecked = (checked) => {
    if (checked) {
      document.getElementById("partial").checked = true;
      setShowPartial({});
    } else {
      document.getElementById("partial").checked = false;
      setShowPartial({ display: "none" });
    }
  };

  // HTML
  return (
    <React.Fragment>
      <div className="content">
        <div className="form-container main">
          <h3>{t("accountPage.title")}</h3>
          <form onSubmit={submitForm}>
            <div className="form-group align-items-center row">
              <label className="col-sm-2 col-form-label">
                <b>{t("accountPage.registerAs")}: </b>
              </label>
              <div className="radio col-sm-2 ">
                <label>
                  <input
                    type="radio"
                    name="role"
                    id="doner"
                    value="doner"
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    checked={role === "doner"}
                  />
                  &nbsp;{t("accountPage.donor")}
                </label>
              </div>
              <div className="radio col-sm-2">
                <label>
                  <input
                    type="radio"
                    name="role"
                    id="temple"
                    value="temple"
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    checked={role === "temple"}
                  />
                  &nbsp;{t("accountPage.temple")}
                </label>
              </div>
            </div>
            <div className="form-group align-items-center row">
              <label className="col-sm-2 col-form-label">
                {role === "temple"
                  ? t("accountPage.templeName") + ":"
                  : t("accountPage.name") + ":"}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.email")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={email}
                  disabled
                />
              </div>
            </div>
            <div className="form-group align-items-center row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.phoneNumber")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="+94771234567"
                  pattern="^\+\d{11}$"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  {t("accountPage.smsNote")}
                </small>
              </div>
            </div>
            <label>
              <b>{t("accountPage.address")}: </b>
              <small className="form-text text-muted">
                {t("accountPage.accountDetailsNote")}
              </small>
            </label>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.unit")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="number"
                  className="form-control"
                  placeholder="House number"
                  value={number}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group align-items-center row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.streetName")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="streetname"
                  className="form-control"
                  placeholder="Street name"
                  value={streetname}
                  onChange={(e) => {
                    setStreetname(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.city")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.province")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="province"
                  className="form-control"
                  placeholder="Province"
                  value={province}
                  onChange={(e) => {
                    setProvince(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.postCode")}:{" "}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="postalcode"
                  className="form-control"
                  placeholder="00000"
                  value={postal}
                  onChange={(e) => {
                    setPostal(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                {t("accountPage.country")}:{" "}
              </label>
              <div className="col-sm-10">
                <select
                  className="custom-select"
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  {Object.keys(countries).map((country) => {
                    return (
                      <option key={countries[country].code} value={country}>
                        {country}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* Temple Fields */}
            <div className="dynamicForm" style={showTemple}>
              <div className="form-group align-items-center row">
                <label className="col-sm-3 col-form-label">
                  {t("accountPage.availableDonationMethods")}:
                </label>
                <div className="col-sm-3 form-check">
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="money-payment"
                    checked={moneyTick}
                    onChange={(e) => {
                      setMoneyTick(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    {t("accountPage.moneyPayment")}
                  </label>
                </div>
                <div className="col-sm-3 form-check">
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="bring-food"
                    checked={bringFoodTick}
                    onChange={(e) => {
                      setBringFoodTick(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    {t("accountPage.bringFood")}
                  </label>
                </div>
                <div className="col-sm-3 form-check">
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="cook-at-temple"
                    checked={cookTick}
                    onChange={(e) => {
                      setCookTick(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    {t("accountPage.cookAtTemple")}
                  </label>
                </div>
              </div>

              <div className="form-group align-items-center row">
                <label className="col-sm-3 col-form-label">
                  {t("accountPage.accountDetails")}:
                </label>
                <div className="col-sm-9">
                  <textarea
                    name="accountDetails"
                    className="form-control"
                    placeholder="Account details with the Country"
                    value={accountDetails}
                    onChange={(e) => {
                      setAccountDetails(e.target.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    {t("accountPage.accountDetailsNote")}
                  </small>
                </div>
              </div>
              <div className="form-group align-items-center row">
                <label className="col-sm-3 col-form-label">
                  {t("accountPage.templeWebsite")}:{" "}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="templeUrl"
                    className="form-control"
                    placeholder="Temple URL"
                    value={templeUrl}
                    onChange={(e) => {
                      setTempleUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group align-items-center row">
                <label className="col-sm-3 col-form-label">
                  {t("accountPage.gMapUrl")}:{" "}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="templeUrl"
                    className="form-control"
                    placeholder="GMAP location"
                    value={gmapUrl}
                    onChange={(e) => {
                      setGmapUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group align-items-center row">
                <label className="col-sm-3 col-form-label">
                  {t("accountPage.additionalDetails")}:
                </label>
                <div className="col-sm-9">
                  <textarea
                    name="additionalDetails"
                    className="form-control"
                    placeholder="Additional information if any..."
                    value={additional}
                    onChange={(e) => {
                      setAdditional(e.target.value);
                    }}
                  />
                </div>
              </div>

              <label>
                <b>{t("accountPage.availableReservationMethods")}: </b>
              </label>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="fullReservation"
                  id="full"
                  onChange={(e) => fullChecked(e.target.checked)}
                />
                <label className="form-check-label">
                  {t("accountPage.fullReservation")}
                </label>
              </div>
              <div id="full" style={showFull}>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.numPersons")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="numberOfPersons-f"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={fNumberofPersons}
                      onChange={(e) => {
                        setFNumberOfPersons(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.costPerPerson")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="costPerPerson-f"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={fCostPerPerson}
                      onChange={(e) => {
                        setFCostPerPerson(e.target.value);
                      }}
                    />
                    <small className="form-text text-muted">
                      {t("accountPage.currencyNote")}
                    </small>
                  </div>
                </div>
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="partialReservation"
                  id="partial"
                  onChange={(e) => partialChecked(e.target.checked)}
                />
                <label className="form-check-label">
                  {t("accountPage.partialReservation")}
                </label>
              </div>
              <div id="partial" style={showPartial}>
                <div className="form-group row">
                  <div className="col-sm-4">&nbsp;</div>
                  <label className="col-sm-8">
                    <b>{t("accountPage.breakfast")} </b>
                  </label>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.numPersons")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="numberOfPersons-b"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={bNumberofPersons}
                      onChange={(e) => {
                        setBNumberOfPersons(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.costPerPerson")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="costPerPerson-b"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={bCostPerPerson}
                      onChange={(e) => {
                        setBCostPerPerson(e.target.value);
                      }}
                    />
                    <small className="form-text text-muted">
                      {t("accountPage.currencyNote")}
                    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-4">&nbsp;</div>
                  <label className="col-sm-8">
                    <b>{t("accountPage.lunch")}</b>
                  </label>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.numPersons")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="numberOfPersons-l"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={lNumberofPersons}
                      onChange={(e) => {
                        setLNumberOfPersons(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.costPerPerson")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="costPerPerson-l"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={lCostPerPerson}
                      onChange={(e) => {
                        setLCostPerPerson(e.target.value);
                      }}
                    />
                    <small className="form-text text-muted">
                      {t("accountPage.currencyNote")}
                    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-4">&nbsp;</div>
                  <label className="col-sm-8">
                    <b>{t("accountPage.tea")} </b>
                  </label>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.numPersons")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="numberOfPersons-t"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={tNumberofPersons}
                      onChange={(e) => {
                        setTNumberOfPersons(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group align-items-center text-right row">
                  <label className="col-sm-4 col-form-label">
                    {t("accountPage.costPerPerson")}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      name="costPerPerson-t"
                      className="form-control"
                      placeholder="0"
                      min="0"
                      value={tCostPerPerson}
                      onChange={(e) => {
                        setTCostPerPerson(e.target.value);
                      }}
                    />
                    <small className="form-text text-muted">
                      {t("accountPage.currencyNote")}
                    </small>
                  </div>
                </div>
              </div>

              <label>
                <b>{t("accountPage.reminderSettings")}: </b>
              </label>
              <div className="form-group align-items-center text-right row">
                <label className="col-sm-4 col-form-label">
                  {t("accountPage.smsReminderPeriod")}:
                </label>
                <div className="col-sm-8">
                  <input
                    type="number"
                    name="smsReminderPeriod"
                    className="form-control"
                    placeholder="Number of days"
                    min="1"
                    max="7"
                    value={smsReminder}
                    onChange={(e) => {
                      setSMSReminder(e.target.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    {t("accountPage.smsReminderNote")}
                  </small>
                </div>
              </div>
              <div className="form-group align-items-center text-right row">
                <label className="col-sm-4 col-form-label">
                  {t("accountPage.emailReminderPeriod")}:
                </label>
                <div className="col-sm-8">
                  <input
                    type="number"
                    name="emailReminderPeriod"
                    className="form-control"
                    placeholder="Number of days"
                    min="1"
                    max="30"
                    value={emailReminder}
                    onChange={(e) => {
                      setEmailReminder(e.target.value);
                    }}
                  />
                  <small className="form-text text-muted">
                    {t("accountPage.emailReminderNote")}
                  </small>
                </div>
              </div>
            </div>

            <div className={statusColor} id="status" role="alert">
              {status}
            </div>
            <div className="col-sm-12 text-right">
              <input type="submit" className="btn btn-warning" />
            </div>
          </form>
        </div>
      </div>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          <br />
          <b>{t("loading")}...</b>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Register;
