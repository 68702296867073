import React from "react";

const TempleImage = ({ image, showDelete, callDeleteImg }) => {
  return (
    <div>
      <div className="galery-photo" id={image.split("/")[2]}>
        {showDelete ? (
          <div
            className="delete-icon"
            onClick={(e) => {
              callDeleteImg(e.target);
            }}
          >
            <i className="fas fa-trash-alt mydelete" id={image} />
          </div>
        ) : (
          ""
        )}
        <a
          href={process.env.REACT_APP_S3_DOMAIN + image}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.REACT_APP_S3_DOMAIN + image}
            className="myimage"
            alt="Temple"
          />
        </a>
      </div>
    </div>
  );
};

export default TempleImage;
