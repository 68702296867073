import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

const Reseve = () => {
  const { t } = useTranslation();
  const [templeId, setTempleId] = useState("");
  const [templeName, setTempleName] = useState("");
  const [year, setYear] = useState(2020);
  const [month, setMonth] = useState(1);
  const [day, setDay] = useState(1);
  const [date, setDate] = useState("2021-01-01");
  const [full, setFull] = useState(false);
  const [showFull, setShowFull] = useState({});
  const [showPartial, setShowParial] = useState({});
  const [breakfast, setBreakfast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [tea, setTea] = useState(false);
  const [availableMethods, setAvailableMethods] = useState([]);
  const [donationMethod, setDonationMethod] = useState("");
  const [purpose, setPurpose] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [status, setStatus] = useState("");
  const [statusColor, setStatusColor] = useState("alert alert-light");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const urlPath = window.location.pathname;
      const paths = urlPath.split("/");
      const tId = paths[2];
      if (!tId) window.location.href = "/";

      try {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();

        const uesrResponse = await fetch(
          process.env.REACT_APP_BACKEND_DOMAIN + "/api/user/" + user.username,
          {
            method: "GET",
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          }
        );
        const userData = await uesrResponse.json();
        if (!userData.role) {
          window.location.href = "/account";
        }
      } catch (err) {
        window.location.href = "/login?url=" + urlPath;
      }

      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/temple/" + tId,
        requestOptions
      );
      const data = await response.json();

      // load data
      if (!data.reservation.fullReservation) setShowFull({ display: "none" });
      if (!data.reservation.partialReservation)
        setShowParial({ display: "none" });
      setAvailableMethods(data.donationMethod);
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      setTempleName(data.name);
      setTempleId(data.id);
      setDay(paths[3]);
      setMonth(paths[4]);
      setYear(paths[5]);
      setDate(
        paths[5] + "-" + zeroPad(paths[4], 2) + "-" + zeroPad(paths[3], 2)
      );
      setLoading(false);
    }
    fetchData();
  }, []);

  function changeDate(e) {
    const value = e.target.value;
    setDate(value);
    const parts = value.split("-");
    setYear(parts[0]);
    setMonth(parts[1]);
    setDay(parts[2]);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus("");
    setStatusColor("alert alert-light");
    let data = {
      templeId: templeId,
      reservationDay: {
        year: year,
        month: month,
        day: day,
      },
      donationMethod: donationMethod,
      purpose: purpose,
    };

    if (full || breakfast || lunch || tea) {
      data.reservation = [];
    }
    if (full || (breakfast && lunch && tea)) {
      data.reservation.push("full");
    } else {
      if (breakfast) data.reservation.push("breakfast");
      if (lunch) data.reservation.push("lunch");
      if (tea) data.reservation.push("tea");
    }

    if (recurring) {
      data.recurring = "yearly";
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: session.idToken.jwtToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/reserve/" + user.username,
        requestOptions
      );
      response = await response.json();
      setLoading(false);
      const message = JSON.stringify(response);
      if (message.includes("id")) {
        setStatus("Successfully reserved!");
        setStatusColor("alert alert-success");
      } else {
        setStatus(response.error);
        setStatusColor("alert alert-danger");
      }
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };

  function fullcheck(e) {
    setFull(!full);
    setBreakfast(false);
    setLunch(false);
    setTea(false);
  }

  return (
    <React.Fragment>
      <div className="content">
        <div className="form-container main">
          <h3>{t("reservation.title")}</h3>
          <form onSubmit={submitForm}>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                {t("reservation.temple")}:{" "}
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="temple"
                  className="form-control"
                  value={templeName}
                  disabled
                />
                <small className="form-text text-muted">ID: {templeId}</small>
              </div>
            </div>
            <div className="form-group align-items-center row">
              <label className="col-sm-3 col-form-label">
                {t("reservation.date")}:{" "}
              </label>
              <div className="col-sm-9">
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  value={date}
                  onChange={changeDate}
                />
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="recurring"
                    id="recurring"
                    onChange={(e) => {
                      setRecurring(e.target.checked);
                    }}
                    checked={recurring}
                  />
                  <label className="form-check-label">
                    {t("reservation.repeat")}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                {t("reservation.method")}:{" "}
              </label>
              <div className="col-sm-9">
                <select
                  className="custom-select"
                  value={donationMethod}
                  onChange={(e) => {
                    setDonationMethod(e.target.value);
                  }}
                >
                  <option key={1} value={"select..."}>
                    {t("reservation.select")}...
                  </option>
                  {availableMethods.map((method) => {
                    return (
                      <option key={method} value={method}>
                        {method === "money-payment"
                          ? t("reservation.money")
                          : method === "bring-food"
                          ? t("reservation.bring")
                          : method === "cook-at-temple"
                          ? t("reservation.cook")
                          : method}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                {t("reservation.reservation")}:{" "}
              </label>
              <div className="col-sm-9">
                <div style={showFull}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={fullcheck}
                      checked={full}
                    />
                    <label className="form-check-label">
                      {t("reservation.full")}
                    </label>
                  </div>
                </div>
                <div style={showPartial}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={(e) => {
                        setBreakfast(!breakfast);
                        setFull(false);
                      }}
                      checked={breakfast}
                    />
                    <label className="form-check-label">
                      {t("reservation.breakfast")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={(e) => {
                        setLunch(!lunch);
                        setFull(false);
                      }}
                      checked={lunch}
                    />
                    <label className="form-check-label">
                      {t("reservation.lunch")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={(e) => {
                        setTea(!tea);
                        setFull(false);
                      }}
                      checked={tea}
                    />
                    <label className="form-check-label">
                      {t("reservation.tea")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group align-items-center row">
              <label className="col-sm-3 col-form-label">
                {t("reservation.purpose")}:
              </label>
              <div className="col-sm-9">
                <textarea
                  name="purpose"
                  className="form-control"
                  placeholder={t("reservation.purposePlaceholder")}
                  maxLength="225"
                  value={purpose}
                  onChange={(e) => {
                    setPurpose(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  {t("reservation.purposeNote")}
                </small>
              </div>
            </div>

            <div className={statusColor} id="status" role="alert">
              {status}
            </div>
            <div className="col-sm-12 text-right">
              <input type="submit" className="btn btn-warning" />
            </div>
          </form>
        </div>
      </div>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <br />
          <b>{t("loading")}...</b>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Reseve;
