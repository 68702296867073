import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Confirm = () => {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    const urlPath = window.location.pathname;
    const paths = urlPath.split("/");
    const token = paths[2];
    setUrl(
      process.env.REACT_APP_BACKEND_DOMAIN + "/api/reserve/confirm/" + token
    );
  }, []);
  return (
    <div className="content">
      <div className="main text-center">
        <h4>{t("respond.confirm")}</h4>
        <a href={url}>
          <button className="btn btn-warning">Confirm</button>
        </a>
      </div>
    </div>
  );
};

export default Confirm;
