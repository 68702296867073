import React from "react";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
        <div className="main">
          <h2>{t('aboutPage.mainTitle')}</h2>
          <p>
            {t('aboutPage.mainDesc')}
            <p>Please read our <a target="_blank" href="/DonateTemple-Privacy-Policy-2023-Feb-v1.pdf">Privacy Policy</a> before using our service.</p>
          </p>
          <h4>{t('aboutPage.templesTitle')}</h4>
          <p>
            {t('aboutPage.templesDesc')}
          </p>
          <h4>{t('aboutPage.donorsTitle')}</h4>
          <p>
            {t('aboutPage.donorsDesc')}
          </p>
          <h4>{t('aboutPage.translationTitle')}</h4>
          <p>{t('aboutPage.translateCredit')}</p>
          <p>Yossaphat Chawla (ไทย | Thai)</p>
          <p>Indra Siswanto (Bahasa Indonesia)</p>
        </div>
    </div>
  );
};

export default About;
