import React from "react";
import "./Temple.css";

function Temple({ templeData }) {
  return (
    <a className="temple-card" href={"/templecalendar/" + templeData.id}>
      <div className="temple" id={templeData.id}>
        <h3>
          {templeData.name}{" "}
          {templeData.temple_validated ? (
            <i
              className="fas fa-check-circle"
              style={{ color: "green", fontSize: "30px" }}
            />
          ) : (
            ""
          )}
        </h3>
        <div className="photo">
          <img
            src={
              templeData.images.length
                ? process.env.REACT_APP_S3_DOMAIN + templeData.images[0]
                : "temple.jpg"
            }
            className="image"
            alt="Temple"
          />
        </div>
        <p>
          <br />
          {templeData.address.city}, {templeData.address.country}
        </p>
      </div>
    </a>
  );
}

export default Temple;
