import React, { useEffect, useState } from "react";
import "./Home.css";
import Temple from "./Temple/Temple";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [temples, setTemples] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const getTemples = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/temple?name=${query}`
      );
      // console.log(response);
      const data = await response.json();
      data.models.reverse();
      setTemples(data.models);
      setLoading(false);
    };
    getTemples();
  }, [query]);

  const submit = (e) => {
    e.preventDefault();
    setQuery(search);
  };

  return (
    <React.Fragment>
      <div className="container">
        <form className="search-form" onSubmit={submit}>
          <input
            className="search-bar"
            type="text"
            value={search}
            placeholder={t("homePage.placeholder")}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            style={{ height: "50px" }}
          />
          <button className="search-button" type="submit">
            {t("homePage.search")}
          </button>
        </form>
        {temples.map((temple) => (
          <Temple key={temple.id} templeData={temple} />
        ))}
      </div>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">{t("loading")}...</span>
          </div>
          <br />
          <b>{t("loading")}...</b>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Home;
