import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Remove = () => {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    const urlPath = window.location.pathname;
    const paths = urlPath.split("/");
    const token = paths[2];
    setUrl(
      process.env.REACT_APP_BACKEND_DOMAIN + "/api/reserve/remove/" + token
    );
  }, []);
  return (
    <div className="content">
      <div className="main text-center">
        <h4>{t("respond.remove")}</h4>
        <a href={url}>
          <button className="btn btn-danger">Remove</button>
        </a>
      </div>
    </div>
  );
};

export default Remove;
