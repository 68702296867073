import React, { useEffect, useState } from "react";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

const signUpConfig = {
  header: "My Customized Sign Up",
  hideAllDefaults: ["phone_number"],
  defaultCountryCode: "1",
  signUpFields: [
    {
      label: "Email",
      key: "email",
      placeholder: "Email",
      required: true,
      hint: null,
      displayOrder: 1,
      type: "email",
    },
    {
      label: "Password",
      key: "password",
      placeholder: "Password",
      required: true,
      hint:
        "Must contain atleast 8 charachters with capital letters, numbers and special characters.",
      displayOrder: 2,
      type: "password",
    },
    {
      label: "Name",
      key: "name",
      placeholder: "Legal name",
      required: true,
      hint: null,
      displayOrder: 3,
      type: "name",
    },
    {
      label: "Phone number",
      key: "phone_number",
      placeholder: "Phone number",
      required: true,
      hint: null,
      displayOrder: 4,
      type: "phone_number",
    },
    // and other custom attributes
  ],
};

const Login = () => {
  const [showHelp, setShowHelp] = useState(false);
  useEffect(() => {
    return onAuthUIStateChange(async (newAuthState) => {
      if (newAuthState === "signedin") {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        };
        const response = await fetch(
          process.env.REACT_APP_BACKEND_DOMAIN + "/api/user/" + user.username,
          requestOptions
        );
        const data = await response.json();
        console.log(user);
        console.log(data);
        if (!data.role) {
          window.location.href = "/account";
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const url = urlParams.get("url");
          if (url) window.location.href = url;
          else window.location.href = "/";
        }
      } else if (newAuthState === "confirmSignUp") {
        setShowHelp(true);
      }
      setInterval(function () {
        let element = document.querySelector("main").querySelector(".hydrated");
        if (element && element.shadowRoot.querySelector("amplify-toast")) {
          element.shadowRoot
            .querySelector("amplify-toast")
            .shadowRoot.querySelector(".toast")
            .setAttribute("style", "top: 50%;");
        }
      }, 1000);
    });
  }, []);

  return (
    <React.Fragment>
      {showHelp ? (
        <div className="help-msg">
          You will recieve an email with the code in few minutes. Enter it here
          or login later with your email and password and enter the code to
          confirm. <br />
          <button
            className="btn btn-warning"
            onClick={() => {
              setShowHelp(false);
            }}
          >
            Enter Code
          </button>
        </div>
      ) : (
        ""
      )}
      <AmplifyAuthenticator>
        <AmplifySignIn
          usernameAlias="email"
          headerText="Sign in to your account"
          slot="sign-in"
        />
        <AmplifySignUp
          usernameAlias="email"
          formFields={signUpConfig.signUpFields}
          headerText="Create a new account"
          slot="sign-up"
        ></AmplifySignUp>
        <AmplifyForgotPassword usernameAlias="email" slot="forgot-password" />
        <div></div>
      </AmplifyAuthenticator>
    </React.Fragment>
  );
};

export default Login;
