import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser().then((currentUser) => {
        setUser(currentUser);
        setName(currentUser.attributes.name.split(" ")[0]);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  async function signOut() {
    await Auth.signOut();
    window.location.reload();
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <div style={{ fontFamily: "Pattaya, sans-serif", fontSize: "120%" }}>
          <img
            style={{ height: "14vw", maxHeight: "70px" }}
            alt="logo"
            src="/DonateTemple.png"
          />{" "}
          DonateTemple.com
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/mybookings">
            <i className="fas fa-calendar-alt" /> {t("navBar.myBookings")}
          </Nav.Link>
          <Nav.Link href="/help">
            <i className="fas fa-question-circle" /> {t("navBar.help")}
          </Nav.Link>
          <Nav.Link href="/about">
            <i className="fas fa-info-circle" /> {t("navBar.about")}
          </Nav.Link>
          <NavDropdown
            title={
              <div style={{ display: "inline-block" }}>
                <i className="fas fa-globe" /> {t("navBar.language")}
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => changeLanguage("en")}>
              English
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage("si")}>
              සිංහල
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage("th")}>
              ไทย
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage("id")}>
              Bahasa Indonesia
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          {user && (
            <Nav.Link href="/account">
              <i className="fas fa-user-edit" /> {t("navBar.account")}{" "}
              {name !== "" ? "(" + name + ")" : ""}
            </Nav.Link>
          )}
          {user && (
            <Nav.Link onClick={signOut}>
              <i className="fas fa-sign-out-alt" /> {t("navBar.logout")}
            </Nav.Link>
          )}
          {!user && (
            <Nav.Link href="/login">
              <i className="fas fa-sign-in-alt" /> {t("navBar.login")}
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
