import React from "react";
//import HomePage from "./components/HomePage/HomePage";
import Menu from "./components/Menu/Menu";
import Main from "./components/Main/Main";
import Amplify from "aws-amplify";
import { withTranslation } from "react-i18next"

const dotenv = require("dotenv");
dotenv.config();

Amplify.configure({
  /* 'aws_project_region': 'ap-southeast-2',
  'aws_cognito_identity_pool_id': process.env.REACT_APP_IDENTITY_POOL_ID,
  'aws_cognito_region': 'ap-southeast-2',
  'aws_user_pools_id': 'ap-southeast-2_T6U35gZHU',
  'aws_user_pools_web_client_id': '175efljfb4on8oijt2olabu9jj',
  'oauth': {}, */
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_USER_POOL_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID (manually created)
    //userPoolId: 'ap-southeast-2_T6U35gZHU',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string) (manually created)
    //userPoolWebClientId: '175efljfb4on8oijt2olabu9jj',

    // OPTIONAL - Amazon Cognito User Pool ID (CDK created)
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string) (CDK created)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

const App = () => {
  return (
    <div className="App">
      <Menu />
      <Main />
    </div>
  );
};

export default withTranslation()(App);
/* export default withAuthenticator(App, {
  usernameAttribute: "email",
  signUpConfig,
  includeGreetings: true
}); */
