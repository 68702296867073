import React, { useEffect, useState } from "react";
import {
  Inject,
  ScheduleComponent,
  Month,
  Agenda,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import "./TempleCalendar.css";
import { Auth } from "aws-amplify";
import TempleImage from "./TempleImage";
import DialogBox from "./DialogBox";
import countries from "./countries.json";
import { useTranslation } from "react-i18next";
const textToImage = require("text-to-image");

const TempleCalendar = () => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState({ display: "none" });
  const [data, setData] = useState([]);
  const [templeId, setTempleId] = useState("");
  const [temple, setTemple] = useState({});
  const [user, setUser] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [emailImage, setEmailImage] = useState("");
  const [phoneImage, setPhoneImage] = useState("");

  useEffect(() => {
    async function loadData() {
      const urlPath = window.location.pathname;
      const paths = urlPath.split("/");
      const tId = paths[2];
      setTempleId(tId);
      if (!tId) window.location.href = "/";

      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
        if (tId === user.username) {
          setShowDelete({ fontSize: "20px" });
        }
      } catch (err) {
        console.error("User not authenticated!");
      }

      const requestOptions = {
        method: "GET",
      };
      let response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/temple/" + tId,
        requestOptions
      );
      response = await response.json();
      setTemple(response);
      if (response.images) {
        setImageCount(response.images.length);
      }
      let imageText = response.email;
      if (imageText.length > 28) {
        const parts = imageText.match(/.{1,28}/g);
        imageText = parts.join("\n");
      }
      textToImage
        .generate(imageText, {
          lineHeight: 20,
          maxWidth: 325,
        })
        .then(function (dataUri) {
          setEmailImage(dataUri);
        });
      textToImage
        .generate(response.phone_number, {
          lineHeight: 20,
          maxWidth: 144,
        })
        .then(function (dataUri) {
          setPhoneImage(dataUri);
        });

      response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/reserve/by-temple/" + tId,
        requestOptions
      );
      response = await response.json();
      const models = response.models;

      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 1);
      let data = [];
      models.map((model) => {
        model.reservation.map((subject) => {
          let year = model.reservationDay.year;
          const month = model.reservationDay.month - 1;
          const day = model.reservationDay.day;
          const reservationDay = new Date(year, month, day);
          if (reservationDay < minDate) {
            year = year + Math.ceil((minDate - reservationDay) / 31536000000);
          }

          let startHour = 7;
          if (subject === "lunch") startHour = 11;
          if (subject === "tea") startHour = 16;
          let endHour = startHour + 3;
          if (subject === "full") endHour = 19;

          const title = subject.charAt(0).toUpperCase() + subject.slice(1);

          const colour = model.recurring ? "green" : "orange";
          const repeat = model.recurring ? true : false;

          const booking = {
            Id: 1,
            Subject: title,
            StartTime: new Date(year, month, day, startHour, 0),
            EndTime: new Date(year, month, day, endHour, 0),
            IsAllDay: false,
            Colour: colour,
            Purpose: model.purpose,
            Donor: model.donor,
            ReservationId: model._id,
            Repeat: repeat,
            Confirmed: model.confirmed,
            Method: model.donationMethod,
          };

          data.push(booking);
          return null;
        });
        return null;
      });
      setData(data);
      setLoading(false);
    }
    loadData();
  }, []);

  const deleteReservation = async (resId) => {
    close();
    setLoading(true);
    const session = await Auth.currentSession();
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: session.idToken.jwtToken,
      },
    };
    let response = await fetch(
      process.env.REACT_APP_BACKEND_DOMAIN +
        "/api/reserve/" +
        templeId +
        "/" +
        resId,
      requestOptions
    );
    response = await response.json();
    setLoading(false);
    if (!response.error) {
      window.location.reload();
    }
  };

  const deleteImageCall = async (id) => {
    setLoading(true);
    const session = await Auth.currentSession();
    close();
    const body = { image: id };
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: session.idToken.jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    let response = await fetch(
      process.env.REACT_APP_BACKEND_DOMAIN + "/api/temple/image/" + templeId,
      requestOptions
    );
    response = await response.json();
    setLoading(false);
    if (!response.error) {
      document.getElementById(id).setAttribute("style", "display: none");
      setImageCount(imageCount - 1);
    }
  };

  const eventTemplate = (props) => {
    return (
      <div className="month-template-wrap" style={{ background: props.Colour }}>
        <div className="subject" style={{ fontSize: "15px" }}>
          {props.Subject === "Breakfast"
            ? t("templeCalendar.breakfast")
            : props.Subject === "Lunch"
            ? t("templeCalendar.lunch")
            : props.Subject === "Tea"
            ? t("templeCalendar.tea")
            : props.Subject === "Full"
            ? t("templeCalendar.full")
            : props.Subject}
        </div>
      </div>
    );
  };

  const close = () => {
    setShowConfirm(false);
    const popup = document.getElementsByClassName("e-quick-popup-wrapper")[0];
    popup.classList.remove("e-popup-open");
    popup.classList.add("e-popup-close");
  };

  const callDelete = (e) => {
    close();
    setDeleteId(e.target.id);
    setDelItem("reservation");
    setShowConfirm(true);
  };

  const header = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <button
                className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Close"
                onClick={close}
              >
                <span className="e-btn-icon e-icons e-close-icon"></span>
              </button>
            </div>
          </div>
        ) : (
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <button
                className="e-delete e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Delete"
                style={showDelete}
                onClick={callDelete}
              >
                <span
                  className="e-btn-icon e-icons e-delete-icon"
                  style={{ fontSize: "20px" }}
                  id={props.ReservationId}
                ></span>
              </button>
              <button
                className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Close"
                onClick={close}
                style={{ fontSize: "20px" }}
              >
                <span
                  className="e-btn-icon e-icons e-close-icon"
                  style={{ fontSize: "20px" }}
                ></span>
              </button>
            </div>
            <div className="e-subject-wrap">
              <div className="e-subject e-text-ellipsis" title={props.Subject}>
                {props.Subject === "Breakfast"
                  ? t("templeCalendar.breakfast")
                  : props.Subject === "Lunch"
                  ? t("templeCalendar.lunch")
                  : props.Subject === "Tea"
                  ? t("templeCalendar.tea")
                  : props.Subject === "Full"
                  ? t("templeCalendar.full")
                  : props.Subject}
                {props.Repeat ? " (" + t("templeCalendar.repeats") + ")" : ""}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const content = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-content e-template">
            <form className="e-schedule-form">
              {t("templeCalendar.makeReservation")}
            </form>
          </div>
        ) : (
          <div className="e-event-content e-template">
            <div className="e-subject-wrap">
              <div className="subject" style={{ fontSize: "15px" }}>
                {t("templeCalendar.time")}: {props.StartTime.toLocaleString()}{" "}
                {props.Confirmed ? (
                  <i
                    className="fas fa-check-circle"
                    style={{ color: "green", fontSize: "17px" }}
                  />
                ) : (
                  ""
                )}
                <br />
                {t("templeCalendar.purpose")}: {props.Purpose}
                <br />
                {t("accountPage.donor")}: {props.Donor}
                <br />
                {t("templeCalendar.method")}:{" "}
                {props.Method === "money-payment"
                  ? t("templeCalendar.money")
                  : props.Method === "bring-food"
                  ? t("templeCalendar.bring")
                  : props.Method === "cook-at-temple"
                  ? t("templeCalendar.cook")
                  : props.Method}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const footer = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-footer">
            <button
              className="e-btn"
              title="Add"
              onClick={add}
              style={{ background: "lightgreen" }}
            >
              {t("templeCalendar.add")}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const add = () => {
    const year = reserveDate.getFullYear();
    const month = reserveDate.getMonth() + 1;
    const day = reserveDate.getDate();
    window.location.href =
      "/reserve/" + templeId + "/" + day + "/" + month + "/" + year;
  };

  let reserveDate = new Date();
  const onCellClick = (e) => {
    if (e.startTime) {
      reserveDate = e.startTime;
    }
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  maxDate.setDate(maxDate.getDate() - 1);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  const getAddressString = (address) => {
    return (
      address.number +
      ", " +
      address.streetname +
      ", " +
      address.city +
      ", " +
      address.province +
      ", " +
      address.postalcode +
      ", " +
      address.country +
      "."
    );
  };

  async function uploadImage(e) {
    setLoading(true);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("templeImage", files[0]);

    const session = await Auth.currentSession();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: session.idToken.jwtToken,
      },
      body: formData,
    };
    let response = await fetch(
      process.env.REACT_APP_BACKEND_DOMAIN + "/api/temple/image/" + templeId,
      requestOptions
    );
    response = await response.json();
    console.log(response);
    setLoading(false);
    if (response.image) {
      window.location.reload();
    }
  }

  const callDeleteImg = (imageId) => {
    setDeleteId(imageId.id.split("/")[2]);
    setDelItem("image");
    setShowConfirm(true);
  };

  const editorTemplate = (e) => {
    return (
      <div className="custom-event-editor">
        <div className="e-textlabel">{t("templeCalendar.makeReservation")}</div>
        <br />
        <div className="e-cell-footer">
          <button
            className="e-bntn"
            title="Add"
            onClick={add}
            style={{ background: "lightgreen" }}
          >
            {t("templeCalendar.add")}
          </button>
        </div>
      </div>
    );
  };

  const numberF =
    temple.reservation && temple.reservation.fullReservation
      ? temple.reservation.fullReservation.numberOfPersons
      : 0;
  const costF =
    temple.reservation && temple.reservation.fullReservation
      ? temple.reservation.fullReservation.costPerPerson
      : 0;
  const numberB =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.breakfast.numberOfPersons
      : 0;
  const numberL =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.lunch.numberOfPersons
      : 0;
  const numberT =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.tea.numberOfPersons
      : 0;
  const costB =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.breakfast.costPerPerson
      : 0;
  const costL =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.lunch.costPerPerson
      : 0;
  const costT =
    temple.reservation && temple.reservation.partialReservation
      ? temple.reservation.partialReservation.tea.costPerPerson
      : 0;

  return (
    <React.Fragment>
      <div className="content">
        <div className="main">
          <h3>
            {temple.name}{" "}
            {temple.temple_validated ? (
              <i
                className="fas fa-check-circle"
                style={{ color: "green", fontSize: "30px" }}
              />
            ) : (
              ""
            )}
          </h3>
          <ScheduleComponent
            minDate={minDate}
            maxDate={maxDate}
            height="550px"
            quickInfoTemplates={{
              header: header,
              content: content,
              footer: footer,
            }}
            editorTemplate={editorTemplate}
            cellClick={onCellClick}
            rowAutoHeight={true}
            eventSettings={{
              dataSource: data,
            }}
          >
            <ViewsDirective>
              <ViewDirective option="Month" eventTemplate={eventTemplate} />
              <ViewDirective option="Agenda" allowVirtualScrolling={true} />
            </ViewsDirective>
            <Inject services={[Month, Agenda]} />
          </ScheduleComponent>
          <p
            className="row"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <small className="form-text text-muted">
              {t("templeCalendar.repeatsYearly")} -{" "}
              <i
                className="fas fa-stop"
                style={{ color: "green", paddingRight: "10px" }}
              />
            </small>
            <small className="form-text text-muted">
              {t("templeCalendar.onlyThisYear")} -{" "}
              <i className="fas fa-stop" style={{ color: "orange" }} />
            </small>
          </p>
          <div className="details">
            <div className="form-container row">
              <div className="col-sm-3">
                <b>{t("templeCalendar.accountDetails")}: </b>
              </div>
              <div className="col-sm-9">
                <p>
                  {temple.accountDetails}
                  {!temple.temple_validated ? (
                    <small
                      className="form-text text-muted"
                      style={{ fontWeight: "bold" }}
                    >
                      {t("templeCalendar.notValidated")}
                    </small>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <div className="form-container row">
              <div className="col-sm-3">
                <b>{t("templeCalendar.address")}: </b>
              </div>
              <div className="col-sm-9">
                <p>{temple.address ? getAddressString(temple.address) : ""}</p>
              </div>
            </div>
            <div className="form-container row">
              <div className="col-sm-3">
                <b>{t("templeCalendar.phone")}: </b>
              </div>
              <div className="col-sm-9">
                <p>
                  <img src={phoneImage} alt="phone" />
                </p>
              </div>
            </div>
            <div className="form-container row">
              <div className="col-sm-3">
                <b>{t("templeCalendar.email")}: </b>
              </div>
              <div className="col-sm-9">
                <p>
                  <img src={emailImage} alt="email" />
                </p>
              </div>
            </div>
            {temple.templeUrl ? (
              <div className="form-container row">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.templeWeb")}: </b>
                </div>
                <div className="col-sm-9">
                  <p>
                    <a
                      href={temple.templeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ wordBreak: "break-all" }}
                    >
                      {temple.templeUrl}
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {temple.gmapUrl ? (
              <div className="form-container row">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.gmap")}: </b>
                </div>
                <div className="col-sm-9">
                  <p>
                    <a
                      href={temple.gmapUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ wordBreak: "break-all" }}
                    >
                      {temple.gmapUrl}
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {temple.reservation ? (
              <div className="form-container row">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.donation")}: </b>
                </div>
                <div className="col-sm-9">
                  <table
                    style={{
                      borderCollapse: "separate",
                      border: "1px solid black",
                    }}
                  >
                    <thead>
                      <tr>
                        <th rowSpan="2" className="cost-cell">
                          {t("templeCalendar.currency")}:{" "}
                          {countries[temple.address.country].currency}
                        </th>
                        <th
                          rowSpan="2"
                          className="cost-cell"
                          style={{ textAlign: "center" }}
                        >
                          {t("templeCalendar.full")}
                        </th>
                        <th
                          className="cost-cell"
                          colSpan="3"
                          style={{ textAlign: "center" }}
                        >
                          {t("templeCalendar.partial")}
                        </th>
                      </tr>
                      <tr>
                        <th className="cost-cell">
                          {t("templeCalendar.breakfast")}
                        </th>
                        <th className="cost-cell">
                          {t("templeCalendar.lunch")}
                        </th>
                        <th className="cost-cell">{t("templeCalendar.tea")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {t("templeCalendar.numPersons")}
                        </td>
                        <td
                          className="cost-cell cost"
                          style={{ textAlign: "right" }}
                        >
                          {numberF}
                        </td>
                        <td
                          className="cost-cell cost"
                          style={{ textAlign: "right" }}
                        >
                          {numberB}
                        </td>
                        <td
                          className="cost-cell cost"
                          style={{ textAlign: "right" }}
                        >
                          {numberL}
                        </td>
                        <td
                          className="cost-cell cost"
                          style={{ textAlign: "right" }}
                        >
                          {numberT}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {t("templeCalendar.costPerPerson")}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {costF}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {costB}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {costL}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {costT}
                        </td>
                      </tr>
                      <tr style={{ fontWeight: "bold" }}>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {t("templeCalendar.total")}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {numberF * costF}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {numberB * costB}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {numberL * costL}
                        </td>
                        <td
                          className="cost-cell"
                          style={{ textAlign: "right" }}
                        >
                          {numberT * costT}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            {temple.emailReminderPeriod ? (
              <div className="form-container row reminderPeriod">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.emailReminderPeriod")}: </b>
                </div>
                <div className="col-sm-9">
                  {temple.emailReminderPeriod}{" "}
                  {t("templeCalendar.daysBeforeReservation")}
                </div>
              </div>
            ) : (
              ""
            )}
            {temple.emailReminderPeriod ? (
              <div className="form-container row reminderPeriod">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.smsReminderPeriod")}: </b>
                </div>
                <div className="col-sm-9">
                  {temple.smsReminderPeriod}{" "}
                  {t("templeCalendar.daysBeforeReservation")}
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            {temple.additionalDetails ? (
              <div className="form-container row">
                <div className="col-sm-3">
                  <b>{t("templeCalendar.additional")}: </b>
                </div>
                <div className="col-sm-9">
                  <p>{temple.additionalDetails}</p>
                </div>
              </div>
            ) : (
              ""
            )}
            {user.username === templeId && imageCount < 5 ? (
              <div className="form-container row" style={{ marginTop: "20px" }}>
                <div className="col-sm-3">
                  <b>{t("templeCalendar.uploadImage")}:</b>
                </div>
                <div className="col-sm-9">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <label className="custom-file-label">
                        {t("templeCalendar.chooseImage")}
                      </label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="fileUpload"
                        onChange={uploadImage}
                        accept="image/png,image/jpeg"
                      />
                    </div>
                  </div>
                  <span className="form-text text-muted">
                    {t("templeCalendar.allowedImageTypes")}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            {temple.images
              ? temple.images.map((image) => {
                  return (
                    <TempleImage
                      key={image}
                      image={image}
                      showDelete={user.username === templeId}
                      callDeleteImg={callDeleteImg}
                    />
                  );
                })
              : ""}
          </div>
        </div>
        {showConfirm && delItem === "image" ? (
          <DialogBox
            type={"delete"}
            msg={t("templeCalendar.dialogQuestion")}
            id={deleteId}
            cbYes={deleteImageCall}
            cbNo={close}
          ></DialogBox>
        ) : (
          ""
        )}
        {showConfirm && delItem === "reservation" ? (
          <DialogBox
            type={"delete"}
            msg={t("templeCalendar.dialogQuestion")}
            id={deleteId}
            cbYes={deleteReservation}
            cbNo={close}
          ></DialogBox>
        ) : (
          ""
        )}
      </div>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <br />
          <b>{t("loading")}...</b>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default TempleCalendar;
