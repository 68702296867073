import React from "react";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { t } = useTranslation();
  return (
    <div className="content">
      <div className="main">
        <h3>{t("status.success")}</h3>
      </div>
    </div>
  );
};

export default Success;
