import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Account from "../Account/Account";
import TempleCalendar from "../TempleCalendar/TempleCalendar";
import MyBookings from "../MyBookings/MyBookings";
import Reserve from "../Reserve/Reserve";
import ReserveUpdate from "../ReserveUpdate/ReserveUpdate";
import Help from "../Help/Help";
import About from "../About/About";
import Error from "../Status/Error";
import Success from "../Status/Success";
import Confirm from "../Respond/Confirm";
import Remove from "../Respond/Remove";

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/account" component={Account} />
      <Route path="/templecalendar" component={TempleCalendar} />
      <Route path="/mybookings" component={MyBookings} />
      <Route path="/reserve" component={Reserve} />
      <Route path="/updatereserve" component={ReserveUpdate} />
      <Route path="/error" component={Error} />
      <Route path="/success" component={Success} />
      <Route path="/confirm" component={Confirm} />
      <Route path="/remove" component={Remove} />
      <Route path="/help" component={Help} />
      <Route path="/about" component={About} />
    </Switch>
  </main>
);

export default Main;
