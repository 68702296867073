import React from "react";

const DialogBox = ({ msg, type, id, cbYes, cbNo }) => {
  return (
    <div className="status" style={{ zIndex: 4000 }}>
      <div
        className="alert alert-warning"
        id="status"
        role="alert"
        style={{
          paddingTop: "5px",
          paddingRight: "0px",
          paddingLeft: "initial",
        }}
      >
        {msg}
        <div className="row float-right">
          {type === "delete" ? (
            <button
              type="button"
              className="btn btn-outline-danger"
              style={{ marginRight: "5px" }}
              onClick={() => {
                cbYes(id);
              }}
            >
              <i className="fas fa-trash-alt" />
            </button>
          ) : (
            ""
          )}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => {
              cbNo();
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
