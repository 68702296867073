import React, { useEffect, useState } from "react";
import {
  Inject,
  ScheduleComponent,
  Agenda,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import "./MyBookings.css";
import { Auth } from "aws-amplify";
import DialogBox from "./DialogBox";
import { useTranslation } from "react-i18next";

const MyCalendar = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [user, setUser] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [resrvationId, setReservationId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      let uId = "";
      try {
        const user = await Auth.currentAuthenticatedUser();
        uId = user.username;
        setUser(user);
      } catch (err) {
        console.error("User not authenticated!");
        window.location.href = "/login?url=/mybookings";
        return;
      }
      let response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/temple`
      );
      response = await response.json();
      const temples = {};
      response.models.forEach((temple) => {
        temples[temple.id] = temple.name;
      });

      const session = await Auth.currentSession();
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      response = await fetch(
        process.env.REACT_APP_BACKEND_DOMAIN + "/api/reserve/by-user/" + uId,
        requestOptions
      );
      response = await response.json();
      const models = response.models;

      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 1);
      let data = [];
      models.map((model) => {
        model.reservation.map((subject) => {
          let year = model.reservationDay.year;
          const month = model.reservationDay.month - 1;
          const day = model.reservationDay.day;
          const reservationDay = new Date(year, month, day);
          if (reservationDay < minDate) {
            year = year + Math.ceil((minDate - reservationDay) / 31536000000);
          }

          let startHour = 7;
          if (subject === "lunch") startHour = 11;
          if (subject === "tea") startHour = 16;
          let endHour = startHour + 3;
          if (subject === "full") endHour = 19;

          const title = subject.charAt(0).toUpperCase() + subject.slice(1);

          const colour = model.recurring ? "green" : "orange";
          const repeat = model.recurring ? true : false;

          let templeName = temples[model.templeId];

          const booking = {
            Id: 1,
            Subject: title,
            StartTime: new Date(year, month, day, startHour, 0),
            EndTime: new Date(year, month, day, endHour, 0),
            IsAllDay: false,
            Colour: colour,
            Purpose: model.purpose,
            ReservationId: model._id,
            TempleName: templeName,
            Repeat: repeat,
            Confirmed: model.confirmed,
            Method: model.donationMethod,
          };

          data.push(booking);
          return null;
        });
        return null;
      });
      setData(data);
      setLoading(false);
    }
    loadData();
  }, []);

  const deleteReservation = async (resId) => {
    setShowStatus(false);
    const session = await Auth.currentSession();
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: session.idToken.jwtToken,
      },
    };
    let response = await fetch(
      process.env.REACT_APP_BACKEND_DOMAIN +
        "/api/reserve/" +
        user.username +
        "/" +
        resId,
      requestOptions
    );
    response = await response.json();
    if (!response.error) {
      window.location.reload();
    }
  };

  const callDelete = (e) => {
    setReservationId(e.target.id);
    close();
    setShowStatus(true);
  };

  const close = () => {
    setShowStatus(false);
    const popup = document.getElementsByClassName("e-quick-popup-wrapper")[0];
    popup.classList.remove("e-popup-open");
    popup.classList.add("e-popup-close");
  };

  const header = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <button
                className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Close"
                onClick={close}
              >
                <span className="e-btn-icon e-icons e-close-icon"></span>
              </button>
            </div>
          </div>
        ) : (
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <a href={"/updatereserve/" + props.ReservationId}>
                <button
                  className="e-edit e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                  title="Edit"
                  style={{ fontSize: "20px" }}
                >
                  <span
                    className="e-btn-icon e-icons e-edit-icon"
                    style={{ fontSize: "20px" }}
                  ></span>
                </button>
              </a>
              <button
                className="e-delete e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Delete"
                onClick={callDelete}
                style={{ fontSize: "20px" }}
              >
                <span
                  className="e-btn-icon e-icons e-delete-icon"
                  style={{ fontSize: "20px" }}
                  id={props.ReservationId}
                ></span>
              </button>
              <button
                className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                title="Close"
                onClick={close}
                style={{ fontSize: "20px" }}
              >
                <span
                  className="e-btn-icon e-icons e-close-icon"
                  style={{ fontSize: "20px" }}
                ></span>
              </button>
            </div>
            <div className="e-subject-wrap">
              <div className="e-subject e-text-ellipsis" title={props.Subject}>
                {props.Subject === "Breakfast"
                  ? t("myBookings.breakfast")
                  : props.Subject === "Lunch"
                  ? t("myBookings.lunch")
                  : props.Subject === "Tea"
                  ? t("myBookings.tea")
                  : props.Subject === "Full"
                  ? t("myBookings.full")
                  : props.Subject}
                {props.Repeat ? " (" + t("myBookings.repeats") + ")" : ""}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const content = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-content e-template"></div>
        ) : (
          <div className="e-event-content e-template">
            <div className="e-subject-wrap">
              <div className="subject" style={{ fontSize: "15px" }}>
                {t("myBookings.temple")}: {props.TempleName} <br />
                {t("myBookings.time")}: {props.StartTime.toLocaleString()}{" "}
                {props.Confirmed ? (
                  <i
                    className="fas fa-check-circle"
                    style={{ color: "green", fontSize: "17px" }}
                  />
                ) : (
                  ""
                )}
                <br />
                {t("myBookings.purpose")}: {props.Purpose}
                <br />
                {t("myBookings.method")}:{" "}
                {props.Method === "money-payment"
                  ? t("myBookings.money")
                  : props.Method === "bring-food"
                  ? t("myBookings.bring")
                  : props.Method === "cook-at-temple"
                  ? t("myBookings.cook")
                  : props.Method}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const footer = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-footer"></div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const editorTemplate = (e) => {
    return (
      <div className="custom-event-editor">
        <div className="e-textlabel">{t("myBookings.redirect")}</div>
        <br />
        <div className="e-cell-footer">
          <button
            className="e-bntn"
            title="view temples"
            onClick={(e) => {
              console.log("view temples");
              window.location.href = "/";
              return;
            }}
            style={{ background: "lightgreen" }}
          >
            {t("myBookings.viewTemples")}
          </button>
        </div>
      </div>
    );
  };

  const eventTemplate = (props) => {
    return (
      <div>
        <div className="e-subject-wrap">
          <div className="e-subject">
            {props.Subject === "Breakfast"
              ? t("myBookings.breakfast")
              : props.Subject === "Lunch"
              ? t("myBookings.lunch")
              : props.Subject === "Tea"
              ? t("myBookings.tea")
              : props.Subject === "Full"
              ? t("myBookings.full")
              : props.Subject}
          </div>
        </div>
        <div className="e-date-time">
          {props.StartTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}{" "}
          -{" "}
          {props.EndTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </div>
      </div>
    );
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  maxDate.setDate(maxDate.getDate() - 1);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  return (
    <React.Fragment>
      <div className="content">
        <div className="main">
          <h3>{t("myBookings.title")}</h3>
          <ScheduleComponent
            minDate={minDate}
            maxDate={maxDate}
            height="550px"
            quickInfoTemplates={{
              header: header,
              content: content,
              footer: footer,
            }}
            editorTemplate={editorTemplate}
            rowAutoHeight={true}
            eventSettings={{
              dataSource: data,
            }}
          >
            <ViewsDirective>
              <ViewDirective
                option="Agenda"
                allowVirtualScrolling={true}
                eventTemplate={eventTemplate}
              />
            </ViewsDirective>
            <Inject services={[Agenda]} />
          </ScheduleComponent>
        </div>
        {showStatus ? (
          <DialogBox
            type={"delete"}
            msg={t("templeCalendar.dialogQuestion")}
            id={resrvationId}
            cbYes={deleteReservation}
            cbNo={close}
          ></DialogBox>
        ) : (
          ""
        )}
      </div>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp; &nbsp;
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <br />
          <b>{t("loading")}...</b>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default MyCalendar;
